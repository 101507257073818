<template>
  <vx-card title="">
    <vs-row class="core-skill-header">
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="8" vs-sm="12" class="mb-lg-0 mb-3">
        <div class="vx-col w-full">
          <label class="text-sm opacity-75"><h3>Rating</h3></label>
          <ul class="demo-alignment">
            <li>
              <vs-radio vs-name="rating" v-model="rating" vs-value="1" @change="onChangeRating($event)">
                1
              </vs-radio>
            </li>
            <li>
              <vs-radio vs-name="rating" v-model="rating" vs-value="2" @change="onChangeRating($event)">
                2
              </vs-radio>
            </li>
            <li>
              <vs-radio vs-name="rating" v-model="rating" vs-value="3" @change="onChangeRating($event)">
                3
              </vs-radio>
            </li>
            <li>
              <vs-radio vs-name="rating" v-model="rating" vs-value="4" @change="onChangeRating($event)">
                4
              </vs-radio>
            </li>
            <li>
              <vs-radio vs-name="rating" v-model="rating" vs-value="5" @change="onChangeRating($event)">
                5
              </vs-radio>
            </li>
          </ul>
        </div>
      </vs-col>
    </vs-row>
    <div class="op-block mt-5">
      <vs-row>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="8">
          <p class="h4">Resources</p>
        </vs-col>
        <vs-divider/>
      </vs-row>
      <template v-if="coreSkillDevelopments.length > 0">
        <vs-row v-if="coreSkillDevelopments.length > 0" :key="index"
                v-for="(item,index) in coreSkillDevelopments">
          <vs-col vs-justify="center" vs-align="center" vs-w="9">
            <p class="h4">{{ item.title }}</p>
            <p>{{ item.description }}</p>
            <p class="h5 mb-2" style="display: inline-block">
              <a v-if="item.link" :href="item.link" target="_blank">
                <vs-button color="primary" type="filled" class="mt-3">Link</vs-button>
              </a>
              <a class="mt-5 ml-5" v-if="item.document" :href="item.document" target="_blank">
                <vs-button color="primary" type="filled" class="mt-3">View
                  Document
                </vs-button>
              </a>
            </p>
          </vs-col>
          <vs-divider/>
        </vs-row>
      </template>
      <template v-else>
        <vs-row>
          <vs-col vs-justify="center" vs-align="center" vs-w="9">
            <p>Sorry no data found.</p>
          </vs-col>
          <vs-divider/>
        </vs-row>
      </template>
    </div>
  </vx-card>
</template>

<script>
import vSelect from 'vue-select'
import {mapActions} from "vuex";

export default {
  data() {
    return {
      rating: "1",
      coreSkills: {},

      coreSkillsOptions: [],
      coreSkillFilter: {},

      coreSkillDevelopments: {},
      coreSkillDevelopmentID: null,

      // Data Sidebar
      addNewDataSidebar: false,
      sidebarData: {},

      currentResource: {}
    }
  },
  watch: {
    coreSkillFilter(obj) {
      this.getCoreSkillDevelopments();
    },
  },
  methods: {
    ...mapActions("coreSkill", [
      "getCoreSkillDevelopment",
      "fetchCoreSkills",
      "logProfessionalDevelopment",
      "setRating"

    ]),

    onChangeRating(event) {
      this.setRating(this.rating)
      this.getCoreSkillDevelopments();
    },

    getCoreSkillDevelopments() {
      let filter = {
        coreSkill: this.coreSkillFilter.value,
        rating: this.rating,
      }

      this.$vs.loading()
      this.getCoreSkillDevelopment(filter)
        .then((res) => {
          this.$vs.loading.close()
          this.coreSkillDevelopments = this.$store.state.coreSkill.coreSkillDevelopments;
        })
        .catch(err => {
          this.$vs.loading.close()
          console.error(err)
        })
    }
  },
  components: {
    vSelect
  },
  created() {
    this.rating = this.$route.params.rating || 1;
    this.$vs.loading()
    this.fetchCoreSkills()
      .then((res) => {
        this.$vs.loading.close()
        this.coreSkillsOptions = this.$store.state.coreSkill.coreSkillsOptions;
        const coreSkillIndex = this.coreSkillsOptions.findIndex((u) => u.value == this.$route.params.coreSkillId)
        this.coreSkillFilter = this.coreSkillsOptions[coreSkillIndex];
        console.log(this.coreSkillFilter);
      })
      .catch(err => {
        this.$vs.loading.close()
        console.error(err)
      })


    const log = {
      coreSkillId: this.$route.params.coreSkillId,
      rating: this.$route.params.rating,
    }
    this.logProfessionalDevelopment(log)
      .then((res) => {
        this.$vs.loading.close()
      })
      .catch(err => {
        this.$vs.loading.close()
      })


  },

}
</script>
